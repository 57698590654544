import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie'
import { verifyToken, RetryOnError } from 'utils/VerifyToken';

export const FetchAllPersonalisedQuestions = () => {
  return useQuery(
    ['fetch-all-personalised-questions'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master/personalize_profile`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>data.data.data.map((question)=>{
      if(question.values[0].type=="checkbox"){
        question.values[0]["answer"]={answer: []}
      }else{
        question.values[0]["answer"]={answer: ''}
      }
      return question
    }),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchMoreAboutYouQuestions = () => {
  return useQuery(
    ['fetch-more-about-you-questions'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master/more_about_you`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>data.data.data.map((question)=>{
      if(question.values[0].type=="checkbox"){
        question.values[0]["answer"]={answer: []}
      }else{
        question.values[0]["answer"]={answer: ''}
      }
      return question
    }),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchAllKycStatus = (mode="listing") => {
  return useQuery(
    ['fetch-all-kyc-status'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master/kyc`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      if(mode==="options"){
        return data.data.data.map((obj)=>({label: obj.values[0].name, value: obj._id}))
      }
      return data.data.data
    },
    refetchOnWindowFocus:false,
    staleTime: Infinity,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchDealFlowId = (type) => {
  return useQuery(['dealflow-id', type], 
    async() => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master/home`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  }, {
    select: (data)=>data.data.data.filter((el)=>el.value==type)[0]["_id"],
    refetchOnWindowFocus:false,
    // onSuccess: (data)=>console.log(data),
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchAcountTypes = ({userType = ''}) => {
  return useQuery(['fetch-account-types', userType], 
    async() => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master/account_type?user_individual_type=${userType}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  }, {
    select: (data)=>data.data.data.map((account)=>({label: account.value, value: account._id})),
    refetchOnWindowFocus:false,
    // onSuccess: (data)=>console.log(data),
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchStages = () => {
  return useQuery(
    ['fetch-master-stages'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master/stage?field=display_order&sort=1`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>data.data.data.map((stage)=>{
      return ({label: stage.value, value:stage['_id']})
    }),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchStatus = () => {
  return useQuery(
    ['fetch-master-status'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master/user_status`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>data.data.data.map((status)=>{
      return ({label: status.value, value:status['_id']})
    }),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchSector = () => {
  return useQuery(
    ['fetch-master-sector'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master/sector`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>data.data.data.map((sector)=>{
      return ({label: sector.value, value:sector['_id']})
    }),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchRealtimeAnalysis = () => {
  return useQuery(
    ['fetch-master-realtime-analysis'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master/realtime_analysis`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>data.data.data.map((analysis)=>{
      return ({label: analysis.value, value:analysis['_id']})
    }),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const AddQuestionApi = () => {
  return useMutation((payload) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/master`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}
export const EditQuestionApi = () => {
  return useMutation(({payload, id}) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/master/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}




export const FetchMaster = (page, search, mode='', sort=1, limit=20, queryString) => {

  return useQuery(
    ['master-listing', page, mode, sort, limit], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>({investors: data.data.data.map(row=>{
      return {key: row.key, value: row.values[0].name}
    }), count: data.data.count, limit: data.data.limit}),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}


export const AddMasterTemplate = () => {
  return useMutation(({payload}) => {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}


export const FetchAllMasterTemplate = (page, search, sort=1, limit=20) => {
  // console.log(page.page,page.search,"page")
  return useQuery(
    ['master-listing-template', page, sort, limit], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master?page=${page.page}&searchText=${page.search}${sort!=0 ? `&sort=${-1}`:""}&limit=${limit}&field=_id`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>({data: data.data.data, count: data.data.count}),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}


export const FetchAllExcelMasterTemplate = (page, search, sort=1, limit=20) => {
  // console.log(page.page,page.search,"page")
  return useQuery(
    ['master-listing-template', page, sort, limit], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/report-master/get-all?page=${page.page}&searchText=${page.search}${sort!=0 ? `&sort=${-1}`:""}&limit=${limit}&field=_id`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>({data: data.data.data, count: data.data.count}),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}




export const FetchMasterTemplateDetails = (id) => {
  
  return useQuery(
    ['startup-details-master', id], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/detail/${id}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      // console.log('sameC',data);
      // if(mode==='edit'){
      //   const savedData = data.data.data[0]
      //   const savedValues = {
      //     name: savedData.name || '',
      //     syndicate_name : savedData.syndicate_name  || '',
      //     share_url: savedData.share_url || '',
      //     email: savedData.email || '',
      //     slug: savedData.slug || '',
      //     logo: savedData.logo || null,
      //     password: '1234'
      //   }

      //   return savedValues
      // }
      return data.data.data[0]
    },
    refetchOnWindowFocus:true,
    enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}



export const FetchMasterTemplateDealDetails = (id,template_id) => {
  
  return useQuery(
    ['startup-details-deal-master', id,template_id], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/deal/${id}?checklist_master_id=${template_id}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      // if(mode==='edit'){
      //   const savedData = data.data.data[0]
      //   const savedValues = {
      //     name: savedData.name || '',
      //     syndicate_name : savedData.syndicate_name  || '',
      //     share_url: savedData.share_url || '',
      //     email: savedData.email || '',
      //     slug: savedData.slug || '',
      //     logo: savedData.logo || null,
      //     password: '1234'
      //   }

      //   return savedValues
      // }
      return data.data.data
    },
    refetchOnWindowFocus:true,
    enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}




export const UpdateMasterTemplateForm = () => {
  return useMutation(({payload, id}) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}



export const FetchTemplateMenu = (id, mode="") => {
  
  return useQuery(
    ['startup-details-menu', id,mode], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/template-menu?startup_deal_id=${id}&type=${mode}&checklist_type=deal`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      if(mode=="deal"){
        return data.data.data
      }
      const selectedData = data.data.data.map((vendor)=>{
        return ({label: vendor.template_name, value:vendor._id})
        
      })
      return {...data.data, selectedData};

    },

    refetchOnWindowFocus:true,
    enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const AllFetchTemplateMenu = (id, mode="") => {
  
  return useQuery(
    ['startup-details-menu-dsdddddddd'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/template-menu?&checklist_type=deal`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      if(mode=="deal"){
        return data.data.data
      }
      const selectedData = data.data.data.map((vendor)=>{
        return ({label: vendor.template_name, value:vendor._id ,period:vendor.checklist_period ? vendor.checklist_period : "onetime"})
        
      })
      return {...data.data, selectedData};

    },

    refetchOnWindowFocus:true,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}


export const FetchAccountTypes = ({ page, sort = 1, search, limit }) => {
    return useQuery(
        ['account_types', page, sort, limit],
        async () => {
            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master/account_type?page=${page}&searchText=${search}&limit=${limit}${
                    sort != 0 ? `&sort=${sort}` : ''
                }`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
           select: (data)=>({data: data.data.data, count: data.data.count, limit:data.data.limit}),
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchSectorTypes = ({ page, sort = 1, search, limit }) => {
    return useQuery(
        ['sector_types', page, sort, limit],
        async () => {
            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master/sector?page=${page}&searchText=${search}&limit=${limit}${
                    sort != 0 ? `&sort=${sort}` : ''
                }`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
          select: (data)=>({data: data.data.data, count: data.data.count, limit:data.data.limit}),
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchRealtimeAnalysisTypes = ({ page, sort = 1, search, limit }) => {
    return useQuery(
        ['realtime_analysis_types', page, sort, limit],
        async () => {
            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master/realtime_analysis?page=${page}&searchText=${search}&limit=${limit}${
                    sort != 0 ? `&sort=${sort}` : ''
                }`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        // {
        //     select: (data) => data.data.data,
        //     refetchOnWindowFocus: false,
        //     retry: (failureCount, error) => {
        //         return RetryOnError(failureCount, error);
        //     },
        //     onError: (err) => {
        //         return verifyToken(err);
        //     }
        // }
        {
          select: (data)=>({data: data.data.data, count: data.data.count, limit:data.data.limit}),
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchPaymentModesTypes = ({ page, sort = 1, search, limit }) => {
    return useQuery(
        ['payment_modes_types', page, sort, limit],
        async () => {
            return await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/admin/master/payment_mode?page=${page}&searchText=${search}&limit=${limit}${
                    sort != 0 ? `&sort=${sort}` : ''
                }`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`
                }
            });
        },
        {
          select: (data)=>({data: data.data.data, count: data.data.count, limit:data.data.limit}),
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error);
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};


export const FetchUnitClassTypes = ({ page, sort = 1, search, limit }) => {
  return useQuery(
      ['unit_class', page, sort, limit],
      async () => {
          return await axios({
              method: 'get',
              url: `${process.env.REACT_APP_BASE_URL}/admin/master/unit_class?page=${page}&searchText=${search}&limit=${limit}${
                  sort != 0 ? `&sort=${sort}` : ''
              }`,
              headers: {
                  Authorization: `Bearer ${Cookies.get('jwt')}`
              }
          });
      },
      {
        select: (data)=>{
          const selectedData = data.data.data.map((vendor)=>{
            return ({...vendor, label: vendor.values[0].name, percentage:vendor.values[0].percentage,type:vendor.values[0].type})
            
          })
          return {data:selectedData,count: data.data.count, limit:data.data.limit };
    
        },
        // select: (data)=>({data: data.data.data, count: data.data.count, limit:data.data.limit}),

          refetchOnWindowFocus: false,
          retry: (failureCount, error) => {
              return RetryOnError(failureCount, error);
          },
          onError: (err) => {
              return verifyToken(err);
          }
      }
  );
};

export const FetchAllUnitClassTypes = () => {
  return useQuery(
      ['unit_class-all'],
      async () => {
          return await axios({
              method: 'get',
              url: `${process.env.REACT_APP_BASE_URL}/admin/master/unit_class`,
              headers: {
                  Authorization: `Bearer ${Cookies.get('jwt')}`
              }
          });
      },
      {
        select: (data)=>{
          const selectedData = data.data.data.map((vendor,index)=>{
            
            return ({unit_class_id:vendor._id, label: vendor.values[0].name,type: vendor.values[0].type, percentage:vendor.values[0].percentage 
              // ,default_class_status: index == 0 ?"Yes":"No"
              })
            
          })
          return {data:selectedData,count: data.data.count, limit:data.data.limit };
    
        },
        // select: (data)=>({data: data.data.data, count: data.data.count, limit:data.data.limit}),

          refetchOnWindowFocus: false,
          retry: (failureCount, error) => {
              return RetryOnError(failureCount, error);
          },
          onError: (err) => {
              return verifyToken(err);
          }
      }
  );
};



export const FetchAllPaymentModesTypes = () => {
  return useQuery(
      ['payment_all_modes_types'],
      async () => {
          return await axios({
              method: 'get',
              url: `${process.env.REACT_APP_BASE_URL}/admin/master/payment_mode`,
              headers: {
                  Authorization: `Bearer ${Cookies.get('jwt')}`
              }
          });
      },
      {
          select: (data) =>data.data.data.map(data => ({ value: data?.value, label:data?.value })),
          retry: (failureCount, error) => {
              return RetryOnError(failureCount, error);
          },
          onError: (err) => {
              return verifyToken(err);
          }
      }
  );
};

export const AddBank = () => {
  return useMutation(({payload}) => {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/admin/bank-account`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

// export const FetchBankAccountDetails = (id) => {
  
//   return useQuery(
//     ['bank-account', id], 
//     () => {
//       return axios({
//         method: 'get',
//         url: `${process.env.REACT_APP_BASE_URL}/admin/bank-account/${id}`,
//         headers: { 
//           'Authorization': `Bearer ${Cookies.get('jwt')}`
//         },
//       }) 
//   },{
//     select: (data)=>{
//       // if(mode==='edit'){
//       //   const savedData = data.data.data[0]
//       //   const savedValues = {
//       //     user_type:'realtime',
//       //     bank_name : savedData.bank_name  || '',
//       //     bank_account_number: savedData.bank_account_number || '',
//       //     ifsc_code: savedData.ifsc_code || '',
//       //     branch_name: savedData.branch_name || '',
//       //     micr_code: savedData.micr_code || null,
//       //     account_holder_name: savedData.account_holder_name || '',
//       //     // recieving_bank_name: savedData.recieving_bank_name || '',
//       //     // recieving_bank_swift_code: savedData.recieving_bank_swift_code || '',
//       //     // correspondance_bank: savedData.correspondance_bank || '',
//       //     // correspondance_bank_swift_code: savedData.correspondance_bank_swift_code || '',
//       //     // bank_clearing_code: savedData.bank_clearing_code || '',
//       //   }
//       //   console.log('dimsima',savedValues);

//       //   return savedValues
//       // }
//       return data.data.data[0]
//     },
//     refetchOnWindowFocus:true,
//     enabled:!!id,
//     // refetchIntervalInBackground:false,
//     retry: (failureCount, error)=>{
//       return RetryOnError(failureCount, error)
//     },
//     onError: (err)=>{
//       return verifyToken(err)
//     },
//   })
// }

export const FetchBankAccountDetails = (id) => {
  return useQuery(
    ['bank-account', id],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/bank-account/detail/${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
      select: (data) => {
        
        return data.data.data[0];
      },
      refetchOnWindowFocus: true,
      enabled: !!id,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};

export const FetchInvoiceAccountDetails = (id) => {
  return useQuery(
    ['invoice_master_data', id],
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/master/master-data/${id}`,
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      })
    }, {
      select: (data) => {
        
        return {
          name: data.data.data[0]?.values[0]?.name,
          address: data.data.data[0]?.values[0]?.address,
          city_name: data.data.data[0]?.values[0]?.city_name,
          country: data.data.data[0]?.values[0]?.country,
          state_name: data.data.data[0]?.values[0]?.state_name,
          email: data.data.data[0]?.values[0]?.email,
          code: data.data.data[0]?.values[0]?.code,
          type: data.data.data[0]?.value,
          account_holder_name: data.data.data[0]?.values[0]?.account_holder_name,
          bank_name: data.data.data[0]?.values[0]?.bank_name,
          account_number: data.data.data[0]?.values[0]?.account_number,
          branch: data.data.data[0]?.values[0]?.branch,
          ifsc_code: data.data.data[0]?.values[0]?.ifsc_code,
          gst_number: data.data.data[0]?.values[0]?.gst_number,
          pan_number: data.data.data[0]?.values[0]?.pan_number,
        };
      },
      refetchOnWindowFocus: true,
      enabled: !!id,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};


export const UpdateBankAccountForm = () => {
  return useMutation(({payload, id}) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/bank-account/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

export const FetchAllBankAccount = (page, search, sort=-1, limit=20) => {
  // console.log(page.search,"page");
  return useQuery(
    ['bank-listing-template', page, sort, limit], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/bank-account?page=${page.page}&searchText=${page.search}${sort!=0 ? `&sort=${sort}`:""}&limit=${limit}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>({data: data.data.data, count: data.data.count}),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchAllInvoiceAccounts = ({ page, sort = 1, search, limit }) => {
  return useQuery(
      ['invoice_lsef,r', page, sort, limit],
      async () => {
          return await axios({
              method: 'get',
              url: `${process.env.REACT_APP_BASE_URL}/admin/master/invoice?page=${page}&searchText=${search}&limit=${limit}${
                  sort != 0 ? `&sort=${sort}` : ''
              }`,
              headers: {
                  Authorization: `Bearer ${Cookies.get('jwt')}`
              }
          });
      },
      {
        select: (data)=>{
          // console.log(data.data.data,"statatatat")
          const selectedData =  data?.data?.data?.length > 0 ? data?.data?.data?.map((item)=>{
            return ({...item, name: item.values[0].name, address:item.values[0].address})
          }) : [];
          return {data: selectedData, count: data.data.count, limit:data.data.limit}
        },

          refetchOnWindowFocus: false,
          retry: (failureCount, error) => {
              return RetryOnError(failureCount, error);
          },
          onError: (err) => {
              return verifyToken(err);
          }
      }
  );
};

export const FetchAllInvoiceStatus = ({ page, sort = 1, search, limit }) => {
  return useQuery(
      ['invoice_l_statutssef,r', page, sort, limit],
      async () => {
          return await axios({
              method: 'get',
              url: `${process.env.REACT_APP_BASE_URL}/admin/master/invoice_status?page=${page}&searchText=${search}&limit=${limit}${
                  sort != 0 ? `&sort=${sort}` : ''
              }`,
              headers: {
                  Authorization: `Bearer ${Cookies.get('jwt')}`
              }
          });
      },
      {
        select: (data)=>{
          // console.log(data.data.data,"statatatat")
          const selectedData =  data.data.data?.map((startup)=>({label: startup.values != undefined ? startup?.values[0]?.name : "" , font_color: startup.values  !=  undefined? startup?.values[0]?.font_color : "",background_color: startup?.values  !=  undefined? startup?.values[0]?.background_color : "",_id:startup._id,updated_by_name:startup?.updated_by_name} ))
          return {data: selectedData, count: data.data.count, limit:data.data.limit}
        },

          refetchOnWindowFocus: false,
          retry: (failureCount, error) => {
              return RetryOnError(failureCount, error);
          },
          onError: (err) => {
              return verifyToken(err);
          }
      }
  );
};
// export const FetchVenturePartner = () => {
//   return useQuery(
//       ['venture_partner'],
//       async () => {
//           return await axios({
//               method: 'get',
//               url: `${process.env.REACT_APP_BASE_URL}/admin/investor/list/fees-percentage`,
//               headers: {
//                   Authorization: `Bearer ${Cookies.get('jwt')}`
//               }
//           });
//       },
//       {
//             select: (data) => data.data.data,
//             refetchOnWindowFocus: false,
//             retry: (failureCount, error) => {
//                 return RetryOnError(failureCount, error);
//             },
//             onError: (err) => {
//                 return verifyToken(err);
//             }
//         }
//   );
// };
export const FetchVenturePartner = (startupDealId) => {
  // console.log(startupDealId);
  return useQuery(
    ['venture_partner', startupDealId], // Include parameters in the query key
    async () => {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/investor/list/fees-percentage`;

      const params = {};
      if (startupDealId) {
        params.startup_deal_id = startupDealId;
      }

      return await axios({
        method: 'get',
        url,
        params, // Pass the parameters in the request
        headers: {
          Authorization: `Bearer ${Cookies.get('jwt')}`
        }
      });
    },
    {
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      onError: (err) => {
        return verifyToken(err);
      }
    }
  );
};

export const FetchDealPartner = (startupDealId) => {
  // console.log(startupDealId);
  return useQuery(
    ['_partner', startupDealId], // Include parameters in the query key
    async () => {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/investor/list/fees-percentage?user_type=partner`;

      const params = {};
      if (startupDealId) {
        params.startup_deal_id = startupDealId;
      }

      return await axios({
        method: 'get',
        url,
        params, // Pass the parameters in the request
        headers: {
          Authorization: `Bearer ${Cookies.get('jwt')}`
        }
      });
    },
    {
      select: (data) => {
        // console.log(data.data,"data.data.datavvvj")
        return data.data.data},
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      onError: (err) => {
        return verifyToken(err);
      }
    }
  );
};

export const FetchAllApprovedPartnersWithAdminAcc = () => {
  // console.log(startupDealId);
  return useQuery(
    ['_partner______aoeingeuosvne'], // Include parameters in the query key
    async () => {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/investor/list/partner-admin-list?user_type=partner`;

      return await axios({
        method: 'get',
        url, // Pass the parameters in the request
        headers: {
          Authorization: `Bearer ${Cookies.get('jwt')}`
        }
      });
    },
    {
      select: (data) => {
        // console.log(data,"data.data.datavvvj")
        return data.data?.data},
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      onError: (err) => {
        return verifyToken(err);
      }
    }
  );
};

// export const UpdateVenturePercentage = () => {
//   return useMutation(({payload}) => {
//     return axios({
//       method: 'put',
//       url: `${process.env.REACT_APP_BASE_URL}/admin/investor/fees-percentage/save`,
//       headers: { 
//         'Authorization': `Bearer ${Cookies.get('jwt')}`,
//         'Content-Type': 'application/json'
//       },
//       data:payload
//     })
//   }, {
//     // retry: (failureCount, error)=>{
//     //   return RetryOnError(failureCount, error)
//     // },
//     retry: 0,
//     onError: (err)=>{
//       return verifyToken(err)
//     },
//   }) 
// }
export const UpdateVenturePercentage = (id) => {
  return useMutation(({ payload }) => {
    const apiUrl = id
      ? `${process.env.REACT_APP_BASE_URL}/admin/deal/fees-percentage/save/${id}`
      : `${process.env.REACT_APP_BASE_URL}/admin/investor/fees-percentage/save`;

    return axios({
      method: 'put',
      url: apiUrl,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data: payload
    });
  }, {
    retry: 0,
    onError: (err) => {
      return verifyToken(err);
    },
  });
};

export const UpdatePartnerFees = (id) => {
  return useMutation(({ payload }) => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/deal/partner-fees-percentage/save/${id}`

    return axios({
      method: 'put',
      url: apiUrl,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data: payload
    });
  }, {
    retry: 0,
    onError: (err) => {
      return verifyToken(err);
    },
  });
};

export const  FetchStartupStage = ({ page, sort = 1, search, limit }) => {
  return useQuery(
      ['sector_types', page, sort, limit],
      async () => {
          return await axios({
              method: 'get',
              url: `${process.env.REACT_APP_BASE_URL}/admin/master/startup_flow_status?page=${page}&searchText=${search}&limit=${limit}${
                  sort != 0 ? `&sort=${sort}` : ''
              }`,
              headers: {
                  Authorization: `Bearer ${Cookies.get('jwt')}`
              }
          });
      },
      {
        select: (data)=>{
          // console.log(data,"Fdgdfgdfgdfgdfgdfgdfgdfgdfgdfgf");
          const data_statup = data?.data?.data?.map((startup)=>({label: startup.values != undefined ? startup?.values[0]?.name : "" , font_color: startup.values  !=  undefined? startup?.values[0]?.font_color : "",background_color: startup?.values  !=  undefined? startup?.values[0]?.background_color : "",_id:startup._id,updated_by_name:startup?.updated_by_name} ))
            return {data: data_statup, count: data?.data?.count, limit:data?.data?.limit}
        },

        // select: (data)=>({data: data.data.data, count: data.data.count, limit:data.data.limit}),
          refetchOnWindowFocus: false,
          retry: (failureCount, error) => {
              return RetryOnError(failureCount, error);
          },
          onError: (err) => {
              return verifyToken(err);
          }
      }
  );
};

export const AddStartupStageApi = () => {
  return useMutation((payload) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/master`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data: {
        key: payload.key,
        values:payload.value
      }
    });
  }, {
    retry: 0,
    onError: (err) => {
      return verifyToken(err);
    },
  });
}

export const EditStartupStageApi = () => {
  return useMutation(({ payload, id }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/master/${id}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data: {
        key: payload.key,
        values: payload.value
      }
    });
  }, {
    retry: 0,
    onError: (err) => {
      return verifyToken(err);
    },
  });
}

export const UpdateTargetAmount = () => {
  return useMutation(({ payload }) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/investor/target-amount/save`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data: payload
    });
  }, {
    retry: 0,
    onError: (err) => {
      return verifyToken(err);
    },
  });
};


export const FetchTargetAmount = () => {
  return useQuery(['target-amount'], 
    async() => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/investor/list/fees-percentage?user_type=partner`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  }, {
    select: (data) => data.data.data,
    refetchOnWindowFocus:false,
    // onSuccess: (data)=>console.log(data),
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
};
export const FetchTemplateMenuStartup = (id, mode="") => {
  
  return useQuery(
    ['startup-menu', id,mode], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/template-menu?startup_deal_id=${id}&type=${mode}&checklist_type=startup`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      if(mode=="deal"){
        return data.data.data
      }
      const selectedData = data.data.data.map((vendor)=>{
        return ({label: vendor.template_name, value:vendor._id})
        
      })
      return {...data.data, selectedData};

    },

    refetchOnWindowFocus:true,
    enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}


export const FetchAllTemplateMenuStartup = (mode = "") => {
  return useQuery(
    ['startup-menu-dddddaaaaad-ddd'], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/checklist-master/template-menu?&checklist_type=startup`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      if(mode=="deal"){
        const selectedData = data.data.data.map((vendor)=>{
          return ({label: vendor.template_name, value:vendor._id})
          
        })
        return {...data.data, selectedData};
      
      }
    

    },

    refetchOnWindowFocus:true,
    // enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchAllReports = (page, search, sort=1, limit=20) => {
  // console.log(page.page,page.search,"page")
  return useQuery(
    ['master-reports-listing-template', page, sort, limit], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/report-master?page=${page.page}&searchText=${page.search}${sort!=0 ? `&sort=${-1}`:""}&limit=${limit}&field=_id`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>({data: data.data.data, count:  data.data.data?.length}),
    refetchOnWindowFocus:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const AddMasterReportAPI = () => {
  return useMutation(({payload}) => {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/admin/report-master`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}


export const AddExcelMasterReportAPI = () => {
  return useMutation(({payload}) => {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/admin/report-master/excel_report`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}


export const FetchSelectedMasterReport = (id) => {
  return useQuery(
    ['report-all-dddddaaaaaddd-ddd',id], 
    () => {
      // let selectedID = id || "";
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/report-master/detail/${id}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>({data: data.data.data[0], count: data.data.data?.length}),

    refetchOnWindowFocus:false,
    enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const  FetchExcelSelectedMasterReport = (id) => {
  return useQuery(
    ['report-all-dddddaaaaaddd-ddd',id], 
    () => {
      let selectedID = id || "";
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/report-master/get-excel-report/${selectedID}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>({data: data.data.data, count: data.data.data?.length}),

    refetchOnWindowFocus:false,
    enabled:!!id && id != null && id != '',
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchSelectedExcelReport = () => {
  return useMutation(
  async  (payload) => {
      const selectedId = payload?.selected || "";
        
        return await axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/admin/report-master/get-excel-report/${selectedId}`,
          headers: { 
            'Authorization': `Bearer ${Cookies.get('jwt')}`
          },
        }) 
    },
    {
      select: (data)=>({data: data.data.data, count: data.data.data?.length}),

      refetchOnWindowFocus:false,
      // enabled:!!id && id != null && id != '',
      // refetchIntervalInBackground:false,
      retry: (failureCount, error)=>{
        return RetryOnError(failureCount, error)
      },
      onError: (err)=>{
        return verifyToken(err)
      },
    }
);
};

export const EditMasterReportAPI = () => {
  return useMutation(({payload, id}) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/report-master/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}


export const EditExcelMasterReportAPI = () => {
  return useMutation(({payload, id}) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/report-master/edit-excel-report/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

export const AttachExcelReportAPI = () => {
  return useMutation(({payload, id}) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/report-master/attach-excel/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

export const FetchAllTemplateReport = (id, mode="",report_type="deal") => {
  
  return useQuery(
    ['startup-menu', id,mode,report_type], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/report-master/template-menu?report_type=${report_type}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      // if(mode=="deal"){
      //   return data.data.data
      // }
      const selectedData = data.data.data.map((report)=>{
        return ({label: report.report_name, value:report._id})
      })
      return {...data.data, selectedData};

    },

    refetchOnWindowFocus:true,
    enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const AttachStartupReport = () => {
  return useMutation(({payload, id}) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/report-master/startup/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        "Content-type": "application/json"
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull"),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

export const FetchMasterTemplateStartupReport = (id) => {
  
  return useQuery(
    ['startup-menu', id], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/report-master/startup/${id}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      // if(mode=="deal"){
        return data.data.data
      // }
      // const selectedData = data.data.data.map((report)=>{
      //   return ({label: report.report_name, value:report._id})
      // })
      // return {...data.data, selectedData};

    },

    refetchOnWindowFocus:true,
    enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const AddStartupReportData = () => {
  return useMutation(({payload,id}) => {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/admin/report-master/report_data/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

export const FetchAllMasterReportGraphs = (fromDate,toDate) => {
  return useMutation(
  async  (payload) => {
      const startupId = payload?.startupId || "";
      const reportId = payload?.reportId || "";
        const graphType = payload?.graphType || "";
        
        return await axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/admin/report-master/report_data/${startupId}/${reportId}?from_month=${fromDate}&to_month=${toDate}&search_text=${graphType}`,
          headers: { 
            'Authorization': `Bearer ${Cookies.get('jwt')}`
          },
        }) 
    },
    {
        select: (data) => ({
          
            data: data.data.data,
        }),
        refetchOnWindowFocus: true,
        retry: 0,
        onError: (err) => {
            // Handle errors or perform actions on error if needed
            console.error('Error generating documents', err);
            throw err; // Re-throw the error for further handling if necessary
        }
    }
);
};


export const GetAllTemplateMenu = (type) => {
  
  return useQuery(
    ['all-template-menu', type], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/list/${type}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      // if(mode=="deal"){
      // }
      const selectedData = data.data.data.map((report)=>{
        return ({label: report.name, value:report._id})
      })
      return {selectedData};

    },

    refetchOnWindowFocus:true,
    // enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const GetAllMasterFeedbackForms = ({page = 1,sort = -1,search="",limit=20,field = "_id"}) => {
  
  return useQuery(
    ['Get-All-Master-Feedb-ack-Forms',page,sort,limit,field], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/feedback-master?page=${page}&searchText=${search}${
          sort != 0 ? `&sort=${sort}` : ''
      }&limit=${limit}&field=${field}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      return {...data.data};

    },

    refetchOnWindowFocus:true,
    // enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const FetchMasterFeedbackDetails = (id) => {
  
  return useQuery(
    ['startup-details-master-feedback', id], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/feedback-master/${id}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{
      // console.log('sameC',data);
      // if(mode==='edit'){
      //   const savedData = data.data.data[0]
      //   const savedValues = {
      //     name: savedData.name || '',
      //     syndicate_name : savedData.syndicate_name  || '',
      //     share_url: savedData.share_url || '',
      //     email: savedData.email || '',
      //     slug: savedData.slug || '',
      //     logo: savedData.logo || null,
      //     password: '1234'
      //   }

      //   return savedValues
      // }
      // console.log(data,"datatatatt")
      return data.data.data.data[0]
    },
    refetchOnWindowFocus:true,
    enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}

export const AddMasterFeedbackForm = () => {
  return useMutation((payload) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/admin/feedback-master`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}
export const EditMasterFeedbackForm = () => {
  return useMutation(({payload, id}) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_URL}/admin/feedback-master/${id}`,
      headers: { 
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
        'Content-Type': 'application/json'
      },
      data:payload
    })
  }, {
    // retry: (failureCount, error)=>{
    //   return RetryOnError(failureCount, error)
    // },
    retry: 0,
    onError: (err)=>{
      return verifyToken(err)
    },
  }) 
}

export const FetchAllMasterTemplateFeedbackForms = ({master_startup_id="",mode = ""}) => {
  return useQuery(
    ['startup-menu-dddddaaaaad-ddd',master_startup_id,mode], 
    () => {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/admin/feedback-master/template-menu?${master_startup_id != "" ? "master_startup_id=" + master_startup_id + "&" : ''}${mode != "" ? 'type=' + mode : ""}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
      }) 
  },{
    select: (data)=>{

        const selectedData = data.data.data.map((vendor)=>{
          return ({label: vendor.template_name, value:vendor._id})
          
        })
        // console.log(data.data,selectedData,"darag")
        return {...data.data, selectedData};
      
      
    

    },

    refetchOnWindowFocus:true,
    // enabled:!!id,
    // refetchIntervalInBackground:false,
    retry: (failureCount, error)=>{
      return RetryOnError(failureCount, error)
    },
    onError: (err)=>{
      return verifyToken(err)
    },
  })
}